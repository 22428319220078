function UnauthorizedPage() {
  return (
    <div className='padding-block'>
    <div className="container">
    <div className="row">
    <div className="col-xs-12 col-md-10">
    <h3>Unauthorized: Please login first</h3>
    </div>
    </div>
    </div>
    </div>
  )
}

export default UnauthorizedPage;